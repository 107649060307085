// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-category-tsx": () => import("./../../../src/pages/category.tsx" /* webpackChunkName: "component---src-pages-category-tsx" */),
  "component---src-pages-link-tsx": () => import("./../../../src/pages/link.tsx" /* webpackChunkName: "component---src-pages-link-tsx" */),
  "component---src-templates-post-list-tsx": () => import("./../../../src/templates/post-list.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

